import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'
import { airSearchData, quotationBreadcrumb } from '../../../common/data/sales'
import TfBreadcrumbs from '../../../components/Common/TfBreadcrumbs'
import { getQuotationHistoryById, getSalesQuotationData, getTenantQuotationSummery, getTenantQuotationSummeryById } from '../../../store/Sales/actions'
import PreviewQuotationModal from '../../InstantRate/Preview/PreviewQuotationModal'
import QuotationModalComp from '../../InstantRate/partials/QuotationModalComp'
import QueriesCompDemo from '../Queries/BKP-quotation-draggable-index'
import FilterSalesComp from '../partials/FilterSalesComp'
import SalesHistoryComp from '../partials/SalesHistoryComp'
import EmailSenderPopUp from '../../Email/emailSenderPopUp'

export default function Quotations() {
    document.title = "Sales || Navigating Freight Costs with Precision||Ultimate Rate Management platform"

    const [quotationData, setQuotationData] = useState(null);
    const [pdfPreviewModal, setPdfPreviewModal] = useState(false);
    const [historyPreviewModal, setHistoryPreviewModal] = useState(false);

    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState(false);
    const [mailmodal, setmailmodal] = useState(false);

    const [isRight, setIsRight] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const { inquiry_transfer_status_data_loader } = useSelector((state) => state?.sales);
    const inputArr = {
        quote_value: [],
        quotation_from: '',
        quotation_to: '',
        org_port: '',
        dest_port: '',
        quote_mode: '',
        quote_status: '',
        carrier_name: '',
        vendor_name: '',
        cargo_type: '',
        container_type: ''
    }
    const [filterDetails, setfilterDetails] = useState(inputArr);
    const navigateType = useLocation();
    const dispatch = useDispatch();
    console.log(navigateType, "navigateType");


    // Preview pdf Modal
    const pdfPreviewModalAction = () => {
        setPdfPreviewModal(!pdfPreviewModal);
    }

    const viewPopupHandler = (data, type) => {
        setModal(true);
        setModalType(type);
    }

    function QuoteModalHandler(type, data = {}) {
        if (type == "history") {
            dispatch(getQuotationHistoryById(data?.id));
            previewHistoryPreviewModalHand()

        }

        else if (type == "pdf") {
            dispatch(getTenantQuotationSummeryById(data?.id))
            pdfPreviewModalAction()
            // dispatch(getAllPartiesCustomerData());
        } else if (type == 'mail') {
            dispatch(getTenantQuotationSummeryById(data?.id))
            setmailmodal(true)
            setQuotationData(data)
        } else {
            dispatch(getTenantQuotationSummeryById(data?.id))
            setModal(!modal);
            viewPopupHandler(airSearchData, type)
        }
    }

    const onCloseClick = () => {
        setmailmodal(false);
    }

    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };

    const applyFilterHandler = () => {
        setIsRight(false);
        if (filterDetails?.carrier_name || filterDetails?.cargo_type || filterDetails?.quotation_from || filterDetails?.quotation_to || filterDetails?.quote_status || filterDetails?.movement || filterDetails?.dest_port || filterDetails?.org_port) {
            let url = `?`;
            url += `${filterDetails?.carrier_name?.id ? `carriers=${filterDetails?.carrier_name.id}&` : ''}`;
            url += `${filterDetails?.vendor_name?.id ? `vendor=${filterDetails?.vendor_name.id}&` : ''}`;
            url += `${filterDetails?.cargo_type?.id ? `cargoType=${filterDetails?.cargo_type.id}&` : ''}`;
            url += `${filterDetails?.org_port?.id ? `originPort=${filterDetails?.org_port.id}&` : ''}`;
            url += `${filterDetails?.dest_port?.id ? `destinationPort=${filterDetails?.dest_port.id}&` : ''}`;
            url += `${filterDetails?.quotation_from ? `validFrom=${filterDetails?.quotation_from}&` : ''}`;
            url += `${filterDetails?.quotation_to ? `validTo=${filterDetails?.quotation_to}&` : ''}`;
            url += `${filterDetails?.quote_status?.id ? `quotationStatus=${filterDetails?.quote_status?.id}&` : ''}`;
            url += `${filterDetails?.container_type?.id ? `containerType=${filterDetails?.container_type?.id}&` : ''}`;
            let newUrl = url.substring(0, url.length - 1);
            dispatch(getTenantQuotationSummery(newUrl));
        }
    }

    const clearValueHandler = () => {
        setfilterDetails(inputArr)
        dispatch(getSalesQuotationData());
    }

    const previewModalHand = () => {
        setPreviewModal(!previewModal);
    }


    const previewHistoryPreviewModalHand = () => {
        setHistoryPreviewModal(!historyPreviewModal)

    }


    useEffect(() => {
        dispatch(getTenantQuotationSummery(""))
    }, [inquiry_transfer_status_data_loader])

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <div className="main_freight_wrapper main_sales_wrapper">
                        <div className="tf_top_breadcrumb_rate_wrap">
                            <TfBreadcrumbs breadcrumb={quotationBreadcrumb} />
                        </div>
                        <QueriesCompDemo pdfPreviewModalAction={pdfPreviewModalAction} toggleRightCanvas={toggleRightCanvas} QuoteModalHandler={QuoteModalHandler} previewModalHand={previewModalHand} />
                    </div>
                </Container>
            </div>

            {/* filter right sidebar */}
            <FilterSalesComp isRight={isRight} toggleRightCanvas={toggleRightCanvas} filterDetails={filterDetails} setfilterDetails={setfilterDetails} applyFilterHandler={applyFilterHandler} clearValueHandler={clearValueHandler} />

            {/* Quotation Modal */}
            <QuotationModalComp quoteModal={modal} setQuoteModal={setModal} QuoteModalHandler={QuoteModalHandler} viewData={modalType !== 'edit' && true} setPreviewModal={setPreviewModal} isShowPreviewQuotationBtn={false} />

            {/* Preview Quotation Modal */}
            <SalesHistoryComp isRight={historyPreviewModal} previewModalHand={previewHistoryPreviewModalHand} filterDetails={filterDetails} setfilterDetails={setfilterDetails} applyFilterHandler={applyFilterHandler} clearValueHandler={clearValueHandler} />


            {pdfPreviewModal && <PreviewQuotationModal previewModal={pdfPreviewModal} previewModalHand={pdfPreviewModalAction} setPreviewModal={setPdfPreviewModal} QuoteModalHandler={QuoteModalHandler} isDraftDisplay={false} isSingleData={true} />}
            <EmailSenderPopUp modal={mailmodal} onCloseClick={onCloseClick} quotationData={quotationData} />
        </>
    )
}
