import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect, useState, version } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
import { edit_icon, preview1, preview2, sitelogo } from '../../../assets/images';
import { formatDate } from '../../../components/Common/CommonLogic';
import PreviewCommonTable from './PreviewCommonTable';
import PreviewFormat1 from './PreviewFormat1';
import PreviewFormat2 from './PreviewFormat2';
import { postTenantQuotationSummeryDetails } from '../../../store/Sales/actions';
import { useDispatch } from 'react-redux';

export default function PreviewQuotationModal({ previewModal, previewModalHand, setPreviewModal, QuoteModalHandler, isDraftDisplay = true ,isSingleData}) {
    const [previewFormat, setPreviewFormat] = useState('1');
    const [customerInfo, setCustomerInfo] = useState();
    const quoteData = useSelector((state) => state.instantRate.quote_selected_data);
    const quoteSelectedTenantdata = useSelector((state) => state.instantRate.quote_selected_tenantdata);
    const { searchForm, $instantActiveTab, instantInquiryId } = useSelector((state) => state?.instantRate);
    const { customer_data } = useSelector((state) => state?.customer);
    const mainChargeObj = useSelector((state) => state?.quotation?.mainChargeObj);
    const dispatch = useDispatch();
    const {
        surchargeCategory_data, status_master, reason_master, oceanPort_data, vendor_data, surchargeCode_data, UOM_data, currency_data, cargoType_data, container_data, oceanPort_terminal
    } = useSelector(state => state?.globalReducer);

    useEffect(() => {
        let data = customer_data && customer_data?.content?.find(obj => obj.id === searchForm?.customerName?.value);
        if(data)
        {
            setCustomerInfo(data);
        }else {
            setCustomerInfo({name:quoteSelectedTenantdata?.contactName,address:""})
        }
    }, [customer_data]);
    const confirmHandler = () => {
        let historyData = quoteData.map(data => {
            return {
                statusTime: new Date().toTimeString().split(' ')[0],
                statusDate: new Date().toISOString().split('T')[0],
                reasonMasterId: reason_master?.find(reason => String(reason?.reasonCode) === "INVALID")?.id,
                statusMasterId: status_master?.find(status => String(status?.statusCode) === "DRAFT")?.id,
            };
        });
        let addedCharges = mainChargeObj.map(data => {
            return {
                tenantFclQuotationsCarrierCharges: data.tariffDetails.flatMap(charge =>
                    charge.fclTariffBreakDowns.map(breakDown => {
                        return {
                            currency: currency_data?.find(currency => String(currency.currencyCode) === String(breakDown?.currencyCode)),
                            chargeHeader: charge?.header,
                            oceanContainer: container_data?.find(container => String(container?.value) === String(breakDown?.containerDetail)),
                            unitOfMeasurement: UOM_data?.find(uom => String(uom?.value) === String(breakDown?.value)),
                            unitCost: breakDown?.unitPerPrice,
                            totalBuyCost: breakDown?.total_sale_cost,
                            chargeName: breakDown?.component?.surchargeCategory,
                            markupType: breakDown?.markup_type || "PERCENTAGE",
                            markupValue: breakDown?.markup_val,
                            chargeMarginAmount: breakDown?.margin_value
                        };
                    })
                )
            };
        });

        let data = {
            tenantCustomerId:  searchForm?.customerName?.value,
            contactName: "new demo",
            contactNo: "324242",
            inquiryDetailId:  instantInquiryId ,
            contactEmail: "demo@gmail.com",
            reasonMasterId: reason_master?.find(reason => String(reason?.reasonCode) === "INVALID")?.id,
            statusMasterId: status_master?.find(status => String(status?.statusCode) === "DRAFT")?.id,
            tenantFclQuotationStatusHistories: historyData,
            tenantFclQuotationCarrierDetails: quoteData.map((data, index) => {
                const vendor = vendor_data?.content?.find(vendor => Number(vendor.id) === Number(data?.carrierId));
                const originalCharges = data.tariffDetails.flatMap(charge =>
                    charge.fclTariffBreakDowns.map(breakDown => {
                        return {
                            currency: currency_data?.find(currency => String(currency.currencyCode) === String(breakDown?.currencyCode)),
                            chargeHeader: charge?.header,
                            tenantOceanFCLFreightRP: { id: charge?.chargeId, version: 0 },
                            oceanContainer: container_data?.find(container => String(container?.value) === String(breakDown?.containerDetail)),
                            unitOfMeasurement: UOM_data?.find(uom => String(uom?.value) === String(breakDown?.uomCode)),
                            unitCost: breakDown?.unitPerPrice,
                            totalBuyCost: breakDown?.unitPerPrice,
                            chargeName: breakDown?.component,
                            markupType: breakDown?.markup_type || "PERCENTAGE",
                            markupValue: breakDown?.markup_val || 10,
                            chargeMarginAmount: breakDown?.margin_value,
                            totalSaleCost: breakDown?.total_sale_cost || breakDown?.amount
                        };
                    })
                );
                const mergedCharges = [
                    ...originalCharges,
                    ...(addedCharges[index]?.tenantFclQuotationsCarrierCharges || [])
                ];

                return {
                    tenantCarrier: vendor,
                    tenantVendorId: vendor?.id,
                    remarks: "default",
                    destinationPort: oceanPort_data?.find(ocean => String(ocean.id) === String(searchForm?.location_to?.value)),
                    originPort: oceanPort_data?.find(ocean => String(ocean.id) === String(searchForm?.location_from?.value)),
                    originCity: vendor?.city,
                    destinationCity: vendor?.city,
                    tenantFclQuotationsCarrierCharges: mergedCharges
                };
            })
        };

        dispatch(postTenantQuotationSummeryDetails(data))
        setPreviewModal(false);
    }

    const handleDownloadPDF = () => {
        // const componentToCapture = document.getElementById('component-to-capture');

        // html2canvas(componentToCapture).then((canvas) => {
        //     const pdf = new jsPDF('p', 'mm', 'a4');
        //     pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 210, 297);
        //     pdf.save('component.pdf');
        // });
        confirmHandler();
    };


    return (
        <>
            <Modal size="md" isOpen={previewModal} toggle={() => { previewModalHand(); }} className='preview_modal_wrap'>
                <div className="modal-header modal-left-button">
                    <button type="button">Change Format</button>
                    <div className="format_image_wrap">
                        <div className={`img ${previewFormat === '1' ? 'active' : ''}`} onClick={() => { setPreviewFormat('1') }}><img src={preview1} alt="Preview" /></div>
                        <div className={`img ${previewFormat === '2' ? 'active' : ''}`} onClick={() => { setPreviewFormat('2') }}><img src={preview2} alt="Preview" /></div>
                    </div>
                </div>
                <div className="modal-header">
                    <button type="button" onClick={() => { setPreviewModal(false); }}><i className='bx bx-plus me-2'></i> Close</button>
                    {isDraftDisplay &&
                        <>
                            <button type="button" onClick={() => { setPreviewModal(false); QuoteModalHandler("edit") }}><img src={edit_icon} alt="Edit" className='me-2' /> Edit</button>
                            <button type="button" onClick={() => { handleDownloadPDF(); }}><i className='bx bx-check-circle me-2'></i>  Draft</button>
                        </>
                    }
                </div>
                <div id="component-to-capture" className="modal-body">
                    {previewFormat === '2' ? <PreviewFormat2 customerInfo={customerInfo} isSingleData={isSingleData}/> : <PreviewFormat1 customerInfo={customerInfo} isSingleData={isSingleData}/>}
                </div>
            </Modal>
        </>
    )
}
